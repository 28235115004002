import React from 'react';
import Navbar from './Navbar'; 
import './App.css'; 
import ImageGallery from './ImageGallery';
import logo from './img/logo8.png';
import Afraid from './img/Illustration/afraid.jpg';
import Crow from './img/Illustration/crow.jpg';
import Requiem from './img/Illustration/requiem.jpg';
import Athena from './img/Illustration/athena-cover.jpg';
import Curio from './img/Illustration/curio.jpg';
import Scopsfun from './img/Illustration/scopsfun.jpg';
import Tavsi from './img/Illustration/tavsi.jpg';
import Ache from './img/Illustration/acheinsecret.jpg';
import Wattlebird from './img/Illustration/wattlebird.jpg'
import Waltz from './img/Illustration/waltz.jpg'
import Car from './img/Illustration/car_i.jpg'
import Nau from './img/Illustration/nau.jpg'
import Story from './img/Illustration/story_i.jpg'
import Self from './img/Illustration/self.jpg'
import Tears from './img/Illustration/tears.jpg'
import Contact from './Contact'
import Design from './Design';

import About from './image/About'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import BirdDetail from './image/WattlebirdDetail';
import CarDetail from './image/CarDetail';
import CrowDetail from './image/CrowDetail';
import RequiemDetail from './image/RequiemDetail';
import StoryDetail from './image/StoryDetail';
import TavsiDetail from './image/TavsiDetail';
import TearsDetail from './image/TearsDetail';
import WaltzDetail from './image/WaltzDetail';
import CampusDetail from './image/CampusDetail';
import AccessoDetail from './image/AccessoDetail';
import AcheInSecretDetail from './image/AcheInSecretDetail';
import AfraidDetail from './image/AfraidDetail';
import AwakenDetail from './image/AwakenDetail';
import BoxDesignDetail from './image/BoxDesignDetail';
import NauDetail from './image/NauDetail';
import ScopsFunDetail from './image/ScopsFunDetail';
import PortraitDetail from './image/SelfDetail';
import SocialDetail from './image/SocialDetail';
import GameDetail from './image/GameDetail';
import AthenaDetail from './image/AthenaDetail';
import BibimcosDetail from './image/BibimcosDetail';
import CurioDetail from './image/CurioDetail';
import MjcareDetail from './image/MjcareDetail';
import SamsungDetail from './image/SamsungDetail';
const images = [
  { src: Curio, alt: 'book illustration'},
  { src: Athena, alt: 'Album Cover Design'},
  { src: Waltz, alt: 'the ethereal waltz'},
  { src: Requiem, alt: 'Requiem'},
  { src: Ache, alt: 'Ache in Secret'},
  { src: Afraid, alt: 'Afraid'},
  { src: Tavsi, alt: 'Echoes of Existence'},
  { src: Wattlebird, alt: 'hear my soul speak.'},
  { src: Crow, alt: 'NFT.NYC2024'},
  { src: Scopsfun, alt: 'longboard design'},
  { src: Car, alt: 'the Black Cat'},
  { src: Self, alt: 'some portraits'},
  { src: Nau, alt: 'concept art'},
  { src: Story, alt: 'the NeverEnding Story'},
  { src: Tears, alt: 'Tears'},
  
  
];



function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <SpeedInsights/>
          <Analytics/>
          <Link to="/">
            <img src={logo} alt="Logo" className="App-logo" />
          </Link>
          <Navbar />
        </header>
        <Sidebar />
        <Routes>
          <Route path="/" element={
            <div className='content'>
              <ImageGallery images={images} />
            </div>
          } />
          <Route path="/contact" element={<Contact />} />
          <Route path="/design" element={<Design />} />
          <Route path="/about" element={<About />} />
          <Route path="/image/hear my soul speak." element={<BirdDetail />} />
          <Route path="/image/the Black Cat" element={<CarDetail />} />
          <Route path="/image/NFT.NYC2024" element={<CrowDetail />} />
          <Route path="/image/requiem" element={<RequiemDetail />} />
          <Route path="/image/the NeverEnding Story" element={<StoryDetail />} />
          <Route path="/image/echoes of existence" element={<TavsiDetail />} />
          <Route path="/image/tears" element={<TearsDetail />} />
          <Route path="/image/the ethereal waltz" element={<WaltzDetail />} />
          <Route path="/image/Promotional Design" element={<CampusDetail />} />
          <Route path="/image/logo design" element={<AccessoDetail />} />
          <Route path="/image/ache in secret" element={<AcheInSecretDetail />} />
          <Route path="/image/afraid" element={<AfraidDetail />} />
          <Route path="/image/Awaken Identity Design" element={<AwakenDetail />} />
          <Route path="/image/box design" element={<BoxDesignDetail />} />
          <Route path="/image/concept art" element={<NauDetail />} />
          <Route path="/image/longboard design" element={<ScopsFunDetail />} />
          <Route path="/image/some portraits" element={<PortraitDetail />} />
          <Route path="/image/social media" element={<SocialDetail />} />
          <Route path="/image/Concept Game Design" element={<GameDetail />} />
          <Route path="/image/Album Cover Design" element={<AthenaDetail />} />
          <Route path="/image/Promotional Design 02" element={<BibimcosDetail />} />
          <Route path="/image/book illustration" element={<CurioDetail />} />
          <Route path="/image/Outdoor AD" element={<MjcareDetail />} />
          <Route path="/image/Flyer Design" element={<SamsungDetail />} />
          

        </Routes>
      </div>
    </Router>
  );
}


export default App;
