import React from 'react';
import wb0 from '../img/Detail/social-detail.jpg';
import './Detail.css';
const SocialDetail = () => {
  return (
    <div className="image-detail-container">
      <img src={wb0} alt="Kuş" />
    </div>
    
  );
};

export default SocialDetail;