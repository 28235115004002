import React from 'react';
import Navbar from './Navbar'; 
import './App.css'; 
import ImageGallery from './ImageGallery';
import logo from './img/logo8.png';
import Accesso from './img/Design/acceso.jpg';
import Box from './img/Design/box.jpg';
import Campus from './img/Design/campus.jpg';
import Samsung from './img/Design/samsung.jpg';
import Card from './img/Design/card.jpg';
import Coffee from './img/Design/coffee.jpg';
import Program from './img/Design/bova.jpg'
import Lon from './img/Design/lon_d.jpg'
import Car from './img/Design/car.jpg'
import Story from './img/Design/story.jpg'
import Bibimcos from './img/Design/promotional-design.jpg'
import Mjcare from './img/Design/mjcare_c.jpg'

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Sidebar from './Sidebar';

const images = [
  { src: Mjcare, alt: 'Outdoor AD'},
  { src: Bibimcos, alt: 'Promotional Design 02'},
  { src: Accesso, alt: 'logo design'},
  { src: Box, alt: 'Box Design'},
  { src: Campus, alt: 'Promotional Design'},
  { src: Card, alt: 'valentines day card design'},
  { src: Program, alt: 'social media'},
  { src: Car, alt: 'the Black Cat'},
  { src: Coffee, alt: 'Awaken Identity Design'},
  { src: Story, alt: 'the NeverEnding Story'},
  { src: Lon, alt: 'Concept Game Design'},
   { src: Samsung, alt: 'Flyer Design'},
  
];



const Design = () => {
  

  return (
   
      <div className="App">
        
            <div className='content'>
              <ImageGallery images={images} />
            </div>
        
      </div>
    
  );


  }
export default Design;
